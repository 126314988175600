<template>
  <section>
    <div
      v-if="isPromoActive"
      :style="{ background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), center 15% / cover url(' + `${activePromoData.homeBgImage}` + ')' }"
      class="relative flex gap-32 bg-cover bg-[center_top_32%] h-[444px] md:h-[627px]"
    >
      <div class="container flex mb-4 items-end md:items-center justify-center md:justify-start">
        <div class="w-full flex flex-col">
          <h1
            class=" text-white text-[30px] leading-[1.2] xs:text-h-2xl md:text-h-3xl font-bold pb-12 md:pb-16 text-left"
            :class="{'md:text-center': isSeeer}"
            v-html="activePromoData.dealHomeHeroTitle">
          </h1>
          <p class="text-h-m md:text-b-xl md:font-medium text-white pb-24 md:pb-32 text-left"
             :class="{'md:!text-center': isSeeer}">
            {{ activePromoData.dealHomeHeroDescription }}
          </p>
          <div class="pb-16 text-left" :class="{'md:!text-center': isSeeer}">
            <div @click="$refs.nextSection?.scrollIntoView({behavior: 'smooth'})" class="text-black no-underline">
              <se-button type="cta" size="xlarge" class="!px-40 hidden md:inline-flex md:visible">
                {{ activePromoData.cta }}
              </se-button>
              <se-button type="cta" size="large" class="!px-24 inline-flex md:hidden ml-0">
                {{ activePromoData.cta }}
              </se-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      :style="{ background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), center 15% / cover url(' + `${header.heroImage}` + ')' }"
      class="py-32 md:py-104 bg-cover bg-[center_top_32%] h-[444px] md:h-[627px] flex items-center justify-center"
    >
      <div class="mx-auto container">
        <h1 class="text-center text-white text-h-2xl md:text-h-3xl font-bold pb-12 md:pb-16">
          {{ header.title }}
        </h1>
        <p class="text-h-m text-white md:text-b-xl md:font-medium text-neutral-80 text-center pb-24 md:pb-32">
          {{ header.subtitle }}
        </p>
        <div class="flex justify-center pb-16">
          <Link href="/advisors/online" class="text-black no-underline">
            <se-button type="cta" size="xlarge" class="!px-40 hidden md:inline-flex md:visible">
              <se-icon name="search" class="mr-6" :size="20"/>
              {{ header.ctaText }}
            </se-button>
            <se-button type="cta" size="large" class="!px-24 inline-flex md:hidden ml-0">
              <se-icon name="search" class="mr-6" :size="16"/>
              {{ header.ctaText }}
            </se-button>
          </Link>
        </div>
        <p class="text-center text-white text-b-s md:text-b-m text-neutral-80">
          {{ header.description.split('@').join(totalReviews) }}
        </p>
      </div>
    </div>
    <div ref="nextSection"></div>
  </section>
</template>

<script setup lang="ts">
import SeButton from '@/src/shared/ui-kit/button/Button';
import SeIcon from '@/src/shared/ui-kit/icon/Icon';
import Link from '@/src/components/Link';
import {theme} from '@/theme';
import {usePromoBanner} from "@/src/shared/helpers/promoBanner";
import {ref} from "vue";

const {header} = theme.home;
const {isPromoActive, activePromoData} = usePromoBanner()

const isSeeer = theme.general.siteName === 'Seeer'

const {totalReviews} = defineProps({
  totalReviews: {
    type: String,
    required: true,
  },
});
</script>

<script lang="ts">
export default {
  name: 'SeStaticHomeHeader',
};
</script>
